// Here you can add other styles
container {
  max-width: 100% !important;
}

.container {
  max-width: 100% !important;
}

.mb-5 {
  margin-bottom: 11px;
}

.center_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}
.cross_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.modal_lead {
  display: flex;
  font-weight: bold;
  color: gray;
  font-size: 17px;
}

.title_primary {
  background-color: #4937e1;
  color: white;
  font-weight: bold;
}

.activebtn {
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  color: #2eb85c;
}

.deactivebtn {
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  color: #e55353;
}

.no_data {
  text-size-adjust: 50px;
  text-align: center;
  display: flex;
  font-weight: bold;
  color: silver;
  font-size: 17px;
}

.check_btn {
  margin-left: 5px;
  font-weight: bold;
  color: gray;
  font-size: 17px;
}

.deac_label {
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  color: #e55353;
}

.act_label {
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  color: #2eb85c;
}

.invoice-button-group button {
  margin: auto 5px;
}

.invoice-signature {
  margin-bottom: 0px;
}

.invoice-detail {
  margin-top: 15px;
}

.invoice-card {
  padding: 15px;
}

.invoice-header {
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.invoice-company-detail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoice-company-detail-label b {
  color: #000;
  font-size: 17px;
  font-weight: 600;
}

.card {
  background: #fff;
  // box-shadow: 0 0 2px black;
  // border-color: #c0c0c0;
  /* box-shadow: 0 0; */
  margin-top: 15px;
  margin-bottom: 15px;
}

* {
  border-color: #c0c0c0;
}

h2 {
  float: right;
}

table.desc-table {
  width: 100%;
}

th,
td {
  padding: 3px;
}

table.desc-table tbody {
  vertical-align: top !important;
}

tr.billtorow {
  background: #8080803b;
}

tr.heading-row {
  background: #f0f0f0;
}
tr.blue-row {
  background: #99d2f2;
}

.footer {
  padding-top: 10px;
  width: 70%;
  display: inline-block;
}

.footer p {
  font-weight: 600;
}

.flex {
  display: flex;
}

.footerside {
  border-left: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  width: 40%;
  float: left;
  padding: 10px 20px;
  margin: auto;
  float: right;

  text-align: center;
}

.tbl {
  width: 100%;
}

.bold {
  font-weight: 600;
}

.sig {
  height: 110px;
}
.sig_maininv {
  height: 80px !important;
}
.proforma_sig,
.cust_in {
  height: 80px;
}
.details_table {
  width: 100%;
}

.details_table,
td {
  border: 1px solid #d2d2d2 ;
  padding: 6px;
}

.hideborder  {
  // border-bottom:  1px solid #ffffff;
  padding: 6px;
}

.details_table,
th {
  border: 1px solid #d2d2d2;
  padding: 6px;
}
.content{
  display: contents;
}
.label {
  display: inline-block;
  width: 110px;
}

​ .desc-table b {
  margin-left: 70px;
}

.block_supply {
  top: 0;
  position: absolute;
}

.block_supply2 {
  top: 24px;
  position: absolute;
}

.block_supply3 {
  top: 48px;
  position: absolute;
}

.total_class {
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
}

.tdmargin {
  padding-left: 8px;
}

.tdmarginalign {
  padding-right: 8px;
  text-align: end;
}

.signarureclass {
  text-align: center;
  margin-bottom: 0;
  font-weight: bold;
}

.brand-section {
  background-color: #0d1033;
  padding: 10px 40px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-6 {
  width: 50%;
  flex: 0 0 auto;
}

.slist {
  list-style: none;
}

.refund td {
  border: 1px solid #d8dbe0;
}

.invoice_radiocss {
  display: flex;
  align-content: stretch;
  justify-content: space-around;
  flex-direction: column-reverse;
}

.activitymodal {
  background: #d8dbe0;
}

.titlefs {
  font-size: 20px;
}

.pactivity {
  margin: 0 !important;
  font-size: 13px !important;
}

.activitymodal.vertical-timeline-element-content {
  padding: 0 !important;
}

.alert {
  padding: 5px 10px;
}

// Jil New Activity Log
body {
  line-height: 1.3em;
  // min-width: 500px;
}

.history-tl-container {
  margin: auto;
  display: block;
  position: relative;
}

.history-tl-container ul.tl {
  margin: 20px 0;
  padding: 0;
  display: inline-block;
}

.history-tl-container ul.tl li {
  position: relative;
  list-style: none;
  margin: auto;
  margin-left: 50px;
  min-height: 50px;
  // border-left:1px dashed #86D6FF;
  padding: 0 0 0px 30px;
}

.history-tl-container ul.tl li:last-child {
  border-left: 0;
}

.history-tl-container ul.tl li.complete::before {
  border-color: green;
  background: green;
}

.history-tl-container ul.tl li.active::before {
  border-color: #258cc7;
  background: #fff;
}

.history-tl-container ul.tl li::before {
  position: absolute;
  left: 195px;
  top: 0px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #258cc7;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.history-tl-container ul.tl li:hover::before {
  transition: all 1000ms ease-in-out;
}

ul.tl li .item-detail {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

li .item-title {
  font-size: 16px;
  font-weight: 600;
}

ul.tl li .timestamp {
  color: #8d8d8d;
  position: absolute;
  width: 100px;
  left: -50%;
  text-align: right;
  font-size: 14px;
}

.btnend {
  text-align: end;
}

.dsgrid {
  display: grid;
}

.alignend {
  text-align: end;
}

.abtnclr {
  color: #fff;
}

.abtnclr:hover {
  color: #fff;
}

.rdw-editor-main {
  overflow-y: auto !important;
  height: 230px !important;
}

.fsize {
  font-size: 14px;
}

.expandstyle {
  padding: 5px 20px;
  background-color: #f3f3f39c;
}

.expandstyle p {
  margin: 10px 10px 0px 10px !important;
}

.avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50em;
}

.divpos {
  position: absolute;
}

.img_center {
  margin: auto;
  display: block;
}

// Theme Changes Jil
.sidebar {
  background-color: #fff !important;
}

.sidebar-brand {
  background-color: #fff !important;
  border-bottom: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.sidebar-nav .nav-link {
  color: #625f6e !important;
}

.header-divider {
  margin-bottom: 2px !important;
  margin-top: 1px !important;
}

.sidebar li.nav-item {
  transition: 0.5s;
}

.sidebar li.nav-item:hover {
  background: #228ce4;
  padding-left: 10px;
  transition: 0.5s;
}

.sidebar li.nav-item:hover a {
  color: #fff !important;
}

.sidebar li.nav-item:hover svg {
  color: #fff !important;
}

.sidebar li .nav-link.active {
  background-color: #228ce4 !important;
}

.sidebar a.nav-link.active {
  color: #fff !important;
}

.sidebar li .nav-link.active svg {
  color: #fff !important;
}

.bg-primary {
  background-color: #228ce4 !important;
}
.bg-danger {
  background-color: #e55353 !important;
}
.color-primary {
  color: #228ce4 !important;
}
.color-danger {
  color: #e55353 !important;
}
.btn-primary {
  background: #228ce4;
  border-color: #228ce4;
}
.btn:disabled {
  background: #6495be;
  border-color: #6495be;
}
.btn.disabled {
  background: #6495be;
  border-color: #6495be;
}

.btn-primary:hover {
  background: #84cdee;
  color: #fff !important;
  border-color: #84cdee;
}

.clearBtn {
  border: 1px solid #82868b !important;
  background-color: transparent !important;
  color: #333 !important;
}
.clearBtn:hover {
  color: #333 !important;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 1px rgb(205 205 205 / 50%) !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.togglebtn {
  position: absolute;
  margin-left: 275px;
  margin-top: 18px;
}

.btn-primary:active {
  background: #228ce4 !important;
  color: #fff !important;
  border-color: #228ce4 !important;
}
.btn-primary:focus {
  background: #228ce4 !important;
  color: #fff !important;
  border-color: #228ce4 !important;
}
.dropdown-item:active {
  background: #228ce4 !important;
  color: #fff !important;
  border-color: #228ce4 !important;
}
.dropdown-item {
  font-size: 13px !important;
  color: #000000
}
.dropdown-item:hover {
  color: #000000
}

.sidebar-nav .nav-group-toggle::after {
  background-image: var(
    --cui-sidebar-nav-group-indicator,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%280, 0, 0, 0.6%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
  ) !important;
}

.sidebar-nav .nav-group-toggle::after:hover {
  background-image: var(
    --cui-sidebar-nav-group-indicator,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%280, 0, 0, 0.6%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
  ) !important;
}

.sidebar-nav .nav-icon {
  color: var(--cui-sidebar-nav-link-icon-color, rgba(0, 0, 0, 0.6)) !important;
}

.sidebar-nav .nav-icon:hover {
  color: var(--cui-sidebar-nav-link-icon-color, rgba(0, 0, 0, 0.6)) !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #228ce4 !important;
  color: #ffffff !important;
}
.nav-link {
  color: #228ce4 !important;
}

.form-control:focus {
  background-color: #fff;
  background-color: var(--cui-input-focus-bg, #fff);
  border-color: rgb(255, 255, 255);
  border-color: rgb(195 223 247);
  box-shadow: 0 0 0 0.25rem rgb(195 223 247) !important;
}
.form-label {
  margin-bottom: 2px;
}

.breadcrumb-item a {
  color: #228ce4 !important;
}

.btn-link {
  color: #228ce4 !important;
}
.btn-secondary a {
  color: #fff !important;
}

.form-check-input:checked {
  background-color: #228ce4 !important;
  border-color: #228ce4 !important;
}

.title_primary {
  background-color: #228ce4 !important;
}

// ---

.mailbtn {
  color: #fff !important;
  text-decoration: none;
}

a.mailbtn:hover {
  color: #fff !important;
}

.accordion-button {
  color: #228ce4 !important;
}
.accordion-button {
  background-color: rgb(229, 239, 248) !important;
}

.accordion-button:focus {
  background-color: #fff;
  background-color: var(--cui-input-focus-bg, #fff);
  border-color: rgb(255, 255, 255);
  border-color: rgb(195 223 247);
  box-shadow: 0 0 0 0.25rem rgb(195 223 247) !important;
}

td {
  word-break: break-all;
}
// div{
//   word-break: break-all;

// }
.sidebar-nav .nav-group.show {
  background-color: rgb(195 223 247) !important;
}

.forgot_link {
  color: #228ce4 !important;
}

.cust_invoice p {
  margin-bottom: 2px !important;
}

.sidebar-nav .nav-group-toggle:after {
  background-image: var(
    --cui-sidebar-nav-group-indicator,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(0, 0, 0)'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E")
  ) !important;
}
.sidebar-nav .nav-group-toggle:after :hover {
  background-image: var(
    --cui-sidebar-nav-group-indicator,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(0, 0, 0)'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E")
  ) !important;
}
.chatdate {
  font-size: 10px;
  text-align: right;
  float: right;
  margin-left: 30px;
  width: 100%;
}
.loaderbtn {
  background: transparent;
  border-color: transparent;
}
.loaderbtn:hover,
.loaderbtn:focus,
.loaderbtn:active {
  background: transparent !important;
  color: transparent !important;
  border-color: transparent !important;
  box-shadow: 0 0 0 1px transparent !important;
}

.basic-single-dropdown {
  width: 100%;
}

.ufont {
  text-transform: capitalize;
}


@media (max-width: 767.98px) {
  html:not([dir=rtl]) .sidebar:not(.sidebar-end):not(.show) {
      margin-left: 0px !important;
      width:50px;
      overflow-x: hidden;
  }
  body{
    margin-left:50px
  }
}