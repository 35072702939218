*,
::after,
::before {
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .center-hor-ver {
    position: absolute;
  }
  .mob_mar_10 {
    margin-bottom: 20px;
  }
}
.center-hor-ver {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 555px !important;
  width: auto;
  padding: 0 15px;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}



.form-container-cus{
  box-shadow: rgb(148 155 163 / 48%) 0px 0px 13px 4px !important;
  padding: 0 15px;
  border-radius: 10px;
  }
  
.cus_form_logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  margin-bottom: 0 !important;
}
