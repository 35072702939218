*,
::after,
::before {
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .center-hor-ver {
    position: absolute;
  }
  .mob_mar_10 {
    margin-bottom: 20px;
  }
}
.center-hor-ver {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 555px !important;
  overflow: auto;
  width: auto;
  padding: 0 15px;
  height: -webkit-fill-available
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}



.form-container-cus{
  /* box-shadow: rgb(148 155 163 / 48%) 0px 0px 13px 4px !important; */
  padding: 0 15px;
  border-radius: 10px;
  }
.form-container-cus1{
    /* box-shadow: rgb(148 155 163 / 20%) 0px 0px 13px 4px !important; */
    padding: 0 15px;
    border-radius: 10px;
}
  
.submit-button:hover {
  background-color: #cf2e2e !important; /* New hover color */
}
.cus_form_logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  margin-bottom: 0 !important;
}

@media (max-width: 767.98px) {
  body {
      margin-left: 0px;
  }
}
.container{
  padding:'0px important';
}
.bg-container{
   background: #f8f8f8 !important
}
.bg-container1{
  background: #f2f2f2 !important
}
.form-control:focus{
     box-shadow:0px 0px 10px 0px rgba(0,0,0,0.5) !important
}